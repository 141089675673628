@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/light';
@use 'sats-ui-lib/tokens/corner-radius';

.select-category {
  $breakpoint: 950px; // NOTE: Must be the same breakpoint as layout
  max-width: 900px;

  &__title {
    margin-bottom: spacing.$l;
  }

  &__categories {
    display: flex;
    flex-wrap: wrap;
    gap: spacing.$xl;
    padding: spacing.$l;
    background-color: light.$surface-primary-default;
    border-radius: corner-radius.$s;
    margin-bottom: spacing.$l;
  }

  &__buttons {
    position: fixed;
    bottom: 0;
    background-color: light.$surface-primary-default;
    width: 100%;
    padding: spacing.$m 0;
    z-index: 10;
    text-align: center;

    @media (min-width: $breakpoint) {
      left: 250px;
      padding-left: spacing.$xxl;
      text-align: left;
    }

    > * + * {
      margin-left: spacing.$xs;
    }
  }

  &__faq-title {
    margin-bottom: spacing.$s;
  }

  &__empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacing.$s;
    padding: spacing.$l;
    background-color: light.$surface-primary-default;
    border-radius: corner-radius.$s;
    margin-bottom: spacing.$xxxl;
    text-align: center;
  }

  &__suggestions {
    margin-top: spacing.$m;
    text-align: center;
  }
}
